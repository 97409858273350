/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'

// Components
import ParseContent from 'components/shared/ParseContent'
import Loading from 'components/shared/Loading'
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimary'
import { ButtonSecondaryCSS } from 'components/elements/Buttons/ButtonSecondary'
import NavLink from 'components/shared/NavLink'
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'

// Images
import Location from 'img/location.inline.svg'
import Phone from 'img/phone.inline.svg'
import Email from 'img/email.inline.svg'

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;
  color: ${({ theme }) => theme.color.primary};

  & .form-duuf-group {
    position: relative;
    margin-bottom: 2.75rem;
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: ${({ theme }) => theme.color.grey};
    border-radius: 50px;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .form-duuf-group-textarea {
    height: 83%;
  }

  & .form-duuf-textarea {
    height: 100%;
    border-radius: 25px;
  }

  & .form-duuf-file-button {
    ${ButtonSecondaryCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-label {
    margin-bottom: 0.65rem !important;
    margin-left: 1rem !important;
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    position: absolute;
    right: 15px;
    top: 5px;
    color: tomato;
  }

  & .form-duuf-group-submit {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0 !important;
  }

  & .form-duuf-submit {
    ${ButtonPrimaryCSS};

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    color: ${({ theme }) => theme.color.primary};
    font-size: 20px !important;
    text-align: center;
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`

const Row = styled.div`
  & h1 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  & svg {
    width: 20px;
    height: 20px;

    & path {
      fill: ${({ theme }) => theme.color.secondary};
    }
  }

  & a:hover {
    text-decoration: underline;
  }

  @media (min-width: 992px) {
    margin-top: -26rem !important;
  }

  @media (max-width: 991px) {
    margin-top: -22rem !important;
  }

  @media (max-width: 575px) {
    margin-top: -10rem !important;
  }
`

const FormWrapper = styled.div`
  border-radius: 50px;
  background-color: #f6f8ff;
`

const Content = styled(ParseContent)`
  color: ${({ theme }) => theme.color.primary};
  & h2 {
    margin-bottom: 2rem;
  }
`

const FormTitle = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    color: ${({ theme }) => theme.color.primary} !important;
  }
`

const Supportlink = styled(NavLink)`
  border: 1px solid ${({ theme }) => theme.color.secondary};
  border-radius: 32px;
  padding: 4px 10px;

  @media (max-width: 575.98px) {
    color: ${({ theme }) => theme.color.primary} !important;
  }
`

const Supp = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.bold};

  & * {
    font-size: ${({ theme }) => theme.font.size.big};
  }

  @media (max-width: 575.98px) {
    margin-top: 4rem;
    color: ${({ theme }) => theme.color.primary} !important;

    & * {
      color: ${({ theme }) => theme.color.primary} !important;
    }
  }
`

interface FormContactProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Form
}

const FormContact = ({ fields }: FormContactProps) => (
  <section className="position-relative mb-5 pb-lg-5">
    <div className="container py-5">
      <Row className="row align-items-end">
        <h1 className="text-white">Contact</h1>
        <div className="d-flex justify-content-center">
          <Supp className="py-3 text-white">
            <span>Klik </span>
            <Supportlink to="/support/" className="text-white">
              hier
            </Supportlink>
            <span className="ms-1"> voor de verschillende support opties</span>
          </Supp>
        </div>
        <div className="col-lg-12">
          <FormWrapper className="p-4 p-sm-5">
            <div className="px-xl-5">
              <FormTitle content={fields.description} className="mb-5" />
              <StyledFormDuuf
                id={1}
                generate={false}
                renderLoading={() => <Loading />}
                renderStatus={({ content }) => (
                  <ParseContent content={content} />
                )}
              >
                <div className="row">
                  <div className="col-lg-6">
                    <FormDuufGroup hideIds={[2]} />
                  </div>
                  <div className="col-lg-6">
                    <FormDuufGroup showIds={[2]} />
                  </div>
                  <div className="mt-5 mt-lg-0">
                    <div className="d-flex justify-content-center">
                      <FormDuufSubmit />
                    </div>
                  </div>
                </div>
              </StyledFormDuuf>
            </div>
          </FormWrapper>
        </div>
        <div className="row mt-5 mb-4">
          <Content
            className="col-lg-6 d-flex align-items-center justify-content-center"
            content={fields.infodescription}
          />
          <div className="col-lg-6">
            <h3 className="mb-2 font-weight-bold font-size-big text-primary mt-5 mt-lg-0">
              GTS-Online BV
            </h3>
            <div className="row font-family-primary font-weight-light">
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-2">
                    <Location />
                  </div>
                  <div className="col-10">
                    <div className="pe-5">
                      <NavLink to={fields.address?.url || '#'}>
                        {fields.address?.title}
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="row mb-1">
                  <div className="col-2">
                    <Phone />
                  </div>
                  <div className="col-10">
                    <a href={fields.phonenumber?.url}>
                      {fields.phonenumber?.title}
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <Email />
                  </div>
                  <div className="col-10">
                    <a href={fields.email?.url}>
                      {fields.email?.title}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="col-lg-5 pb-lg-5 mt-5 mt-md-0">
          <div className="row">
            <div className="col-xl-8">
              <div className="row justify-content-center justify-content-xl-between mt-5 mt-lg-0">
                <div className="col-5 d-flex align-items-center justify-content-center">
                  <SupportTitle>Support</SupportTitle>
                </div>
                {fields.support?.map((item, index) => (
                  <div className="col-5 mb-2 mt-1 d-flex justify-content-center">
                    <SupportBlock
                      key={index}
                      className="d-flex flex-column align-items-center mb-3"
                    >
                      <NavLink to={item?.link?.url || '#'}>
                        <div className="d-flex flex-column align-items-center py-3 px-4 py-sm-4 px-sm-5">
                          <Icon image={item?.icon} alt="" className="mb-2" />
                          {item?.link?.title}
                        </div>
                      </NavLink>
                    </SupportBlock>
                  </div>
                ))}
              </div>
            </div>
          </div>

        </div> */}
      </Row>
    </div>
  </section>
)

export default FormContact
