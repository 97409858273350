/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'
import ParseContent from 'components/shared/ParseContent'
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimary'
import { ButtonSecondaryCSS } from 'components/elements/Buttons/ButtonSecondary'

// Hooks
import useLayout from 'hooks/useLayout'

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;
  color: ${({ theme }) => theme.color.primary};

  & .form-duuf-group {
    position: relative;
    margin-bottom: 2.75rem;
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: ${({ theme }) => theme.color.grey};
    border-radius: 50px;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .form-duuf-group-textarea {
    height: 83%;
  }

  & .form-duuf-textarea {
    height: 100%;
    border-radius: 25px;
  }

  & .form-duuf-file-button {
    ${ButtonSecondaryCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-label {
    margin-bottom: 0.65rem !important;
    margin-left: 1rem !important;
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    position: absolute;
    right: 15px;
    top: 5px;
    color: tomato;
  }

  & .form-duuf-group-submit {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0 !important;
  }

  & .form-duuf-submit {
    ${ButtonPrimaryCSS};

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    color: ${({ theme }) => theme.color.primary};
    font-size: 20px !important;
    text-align: center;
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`

const Row = styled.div`
  & h1 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  & svg {
    width: 20px;
    height: 20px;

    & path {
      fill: ${({ theme }) => theme.color.secondary};
    }
  }

  & a:hover {
    text-decoration: underline;
  }

  @media (min-width: 992px) {
    margin-top: -26rem !important;
  }

  @media (max-width: 991px) {
    margin-top: -22rem !important;
  }

  @media (max-width: 575px) {
    margin-top: -10rem !important;
  }
`

const FormWrapper = styled.div`
  border-radius: 50px;
  background-color: #f6f8ff;
`

const FormTitle = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6,
  & p {
    color: ${({ theme }) => theme.color.primary} !important;
  }

  & p {
    font-size: ${({ theme }) => theme.font.size.big};
  }
`

const Supportlink = styled.a`
  background-color: ${({ theme }) => theme.color.grey};
  border-radius: 32px;
  padding: 12px 24px;
  margin-right: 15px;
  font-weight: ${({ theme }) => theme.font.weight.bold};

  &:hover {
    color: ${({ theme }) => theme.color.primary} !important;
  }
`

const Popuplink = styled.button`
  background-color: ${({ theme }) => theme.color.grey};
  border-radius: 32px;
  padding: 12px 24px;
  margin-right: 15px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.color.primary};

  &:hover {
    text-decoration: underline;
  }
`

const Wrap = styled.div`
  @media (min-width: 576px) {
    padding-bottom: 9rem;
  }
  @media (max-width: 575.98px) {
    padding-bottom: 3rem;
  }
`

interface FormSupportProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Form
}

const FormSupport = ({ fields }: FormSupportProps) => {
  const layout = useLayout()
  return (
    <section className="position-relative mb-5 pb-lg-5">
      <div className="container py-5">
        <Row className="row align-items-end justify-content-center">
          <h1 className="text-white">Support</h1>
          <div className="col-lg-10">
            <Wrap
              style={{
                background: '#FFF',
                borderTopRightRadius: 34,
                borderTopLeftRadius: 34,
                paddingTop: '3rem',
                marginTop: '5rem',
              }}
            >
              <FormTitle content={fields.description} className="mb-5" />

              <div className="d-flex flex-column flex-sm-row justify-content-center pt-sm-5 pt-md-0 mt-sm-5 mt-md-0">
                {fields.support?.map((node, index) => {
                  if (node?.link?.url?.includes('#anydesk')) {
                    return (
                      <Popuplink
                        key={index}
                        onClick={() => layout.setAnydeskModal(true)}
                        className="text-center mb-4 mb-sm-0"
                      >
                        {node?.link?.title}
                      </Popuplink>
                    )
                  }
                  return (
                    <Supportlink
                      href={node?.link?.url || '#'}
                      key={index}
                      target="_blank"
                      rel="noreferrer"
                      className="text-center mb-4 mb-sm-0"
                    >
                      {node?.link?.title}
                    </Supportlink>
                  )
                })}
              </div>
            </Wrap>
          </div>
          <div className="col-lg-12">
            <FormWrapper className="p-4 p-sm-5">
              <FormTitle className="mb-5" content={fields.infodescription} />
              <div className="px-xl-5">
                {/* @ts-ignore */}
                <StyledFormDuuf id={2} generate={false}>
                  <div className="row">
                    <div className="col-lg-6">
                      <FormDuufGroup hideIds={[2]} />
                    </div>
                    <div className="col-lg-6">
                      <FormDuufGroup showIds={[2]} />
                    </div>
                    <div className="mt-5 mt-lg-0">
                      <div className="d-flex justify-content-center">
                        <FormDuufSubmit />
                      </div>
                    </div>
                  </div>
                </StyledFormDuuf>
              </div>
            </FormWrapper>
          </div>
        </Row>
      </div>
    </section>
  )
}

export default FormSupport
